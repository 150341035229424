<template>
  <v-container fluid class="down-top-padding">
    <v-card class="white pa-3">
      <h1 class="text-center subtitle-4 black--text">انشاء دورة جديدة</h1>
      <h5 class="text-center subtitle-4 black--text mt-1">
        العدد الكلي {{ table.totalData }}
      </h5>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="info" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="dialogAdd.open = true">
              اضافة <v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="searching" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              itemsPerPageOptions: [10, 50, 100]
            }">
            <template slot="item._id" slot-scope="props">
              {{
                (tableOptions.page - 1) * tableOptions.itemsPerPage +
                props.index +
                1
              }}
            </template>
            <template v-slot:item.courses_duration="{ item }">
              {{ item.courses_duration + ' يوم' }} 
            </template>
            <template v-slot:item.courses_hours="{ item }">
              {{ item.courses_hours + ' ساعة' }} 
            </template>
            <template v-slot:item.courses_price="{ item }">
              {{ numberWithComma(item.courses_price) }}
            </template>
            <template v-slot:item.courses_lecturer_price="{ item }">
              {{ numberWithComma(item.courses_lecturer_price) }}
            </template>
            <template v-slot:item.courses_agency_price="{ item }">
              {{ numberWithComma(item.courses_agency_price) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="showInfo(item)">
                    fa-info-circle
                  </v-icon>
                </template>
                <span>عرض المعلومات</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="info" class="mx-2" v-bind="attrs" size="20" v-on="on" @click="Edit(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="dialogAdd.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogAdd.isFormValid">
              <v-row>
                <!-- courses_name -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="addData.courses_name_id" :items="all_courses.data" item-text="courses_name"
                    item-value="_id" :rules="Rules.required" dense label="الدورة" outlined clearable />
                </v-col>
                <!-- courses_duration -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="addData.courses_duration" :rules="Rules.required" dense label="المدة (ايام)"
                    outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_hours -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="addData.courses_hours" :rules="Rules.required" dense label="عدد الساعات" outlined
                    @keypress="isNumberWithComma" />
                </v-col>
                <!-- courses_start_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="start_date" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.courses_start_date" label="تاريخ بدء الدورة" readonly v-bind="attrs"
                        v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.courses_start_date" @input="start_date = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_registration_start_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="start_date_registration" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.courses_registration_start_date" label="تاريخ بدء التسجيل" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.courses_registration_start_date"
                      @input="start_date = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_registration_end_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="end_date_registration" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.courses_registration_end_date" label="تاريخ انتهاء التسجيل" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.courses_registration_end_date"
                      @input="end_date_registration = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_time -->
                <v-col cols="12" md="4">
                  <v-dialog ref="dialog" v-model="time_picker" :return-value.sync="addData.courses_time" persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.courses_time" label="وقت الدورة"
                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense
                        clearable></v-text-field>
                    </template>
                    <v-time-picker v-if="time_picker" v-model="addData.courses_time" full-width>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="time_picker = false">
                        الغاء
                      </v-btn>
                      <v-btn text color="info" @click="$refs.dialog.save(addData.courses_time)">
                        حفظ
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <!-- courses_lecturer -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="addData.courses_lecturer" :items="all_teachers.data" item-text="name"
                    item-value="_id" :rules="Rules.required" outlined dense label="الاستاذ" clearable></v-autocomplete>
                </v-col>
                <!-- courses_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="addData.courses_price" :rules="Rules.required" dense label="سعر الدورة" outlined
                    @keypress="isNumber" />
                </v-col>
                <!-- courses_lecturer_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="addData.courses_lecturer_price" :rules="Rules.required" dense
                    label="مبلغ الاستاذ" outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_agency_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="addData.courses_agency_price" :rules="Rules.required" dense label="مبلغ الوكالة"
                    outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_days -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="addData.courses_days" :items="week_days" :rules="Rules.required" dense
                    label="ايام الدورة" multiple chips outlined clearable />
                </v-col>
                <!-- courses_note -->
                <v-col cols="12">
                  <v-textarea v-model="addData.courses_note" rows="2" dense label="الملاحظة"
                    outlined />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAdd.open = false">
            الغاء
          </v-btn>
          <v-btn color="secondary white--text" :loading="dialogAdd.loading" :disabled="!dialogAdd.isFormValid"
            @click="submitAdd">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add discount dialog -->

    <!-- edit dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <!-- courses_name -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="editedItem.courses_name_id" :items="all_courses.data" item-text="courses_name"
                    item-value="_id" :rules="Rules.required" dense label="الدورة" outlined clearable />
                </v-col>
                <!-- courses_duration -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="editedItem.courses_duration" :rules="Rules.required" dense label="المدة (ايام)"
                    outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_hours -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="editedItem.courses_hours" :rules="Rules.required" dense label="عدد الساعات" outlined
                    @keypress="isNumberWithComma" />
                </v-col>
                <!-- courses_start_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="start_date_edit" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.courses_start_date" label="تاريخ بدء الدورة" readonly v-bind="attrs"
                        v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.courses_start_date" @input="start_date_edit = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_registration_start_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="start_date_registration_edit" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.courses_registration_start_date" label="تاريخ بدء التسجيل" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.courses_registration_start_date"
                      @input="start_date_registration_edit = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_registration_end_date -->
                <v-col cols="12" md="4">
                  <v-menu v-model="end_date_registration_edit" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.courses_registration_end_date" label="تاريخ انتهاء التسجيل" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :rules="Rules.required"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.courses_registration_end_date"
                      @input="end_date_registration_edit = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- courses_time -->
                <v-col cols="12" md="4">
                  <v-dialog ref="dialog" v-model="time_picker_edit" :return-value.sync="editedItem.courses_time" persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.courses_time" label="وقت الدورة"
                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense
                        clearable></v-text-field>
                    </template>
                    <v-time-picker v-if="time_picker_edit" v-model="editedItem.courses_time" full-width>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="time_picker_edit = false">
                        الغاء
                      </v-btn>
                      <v-btn text color="info" @click="$refs.dialog.save(editedItem.courses_time)">
                        حفظ
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <!-- courses_lecturer -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="editedItem.courses_lecturer" :items="all_teachers.data" item-text="name"
                    item-value="_id" :rules="Rules.required" outlined dense label="الاستاذ" clearable></v-autocomplete>
                </v-col>
                <!-- courses_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="editedItem.courses_price" :rules="Rules.required" dense label="سعر الدورة" outlined
                    @keypress="isNumber" />
                </v-col>
                <!-- courses_lecturer_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="editedItem.courses_lecturer_price" :rules="Rules.required" dense
                    label="مبلغ الاستاذ" outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_agency_price -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="editedItem.courses_agency_price" :rules="Rules.required" dense label="مبلغ الوكالة"
                    outlined @keypress="isNumber" />
                </v-col>
                <!-- courses_days -->
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="editedItem.courses_days" :items="week_days" :rules="Rules.required" dense
                    label="ايام الدورة" multiple chips outlined clearable />
                </v-col>
                <!-- courses_note -->
                <v-col cols="12">
                  <v-textarea v-model="editedItem.courses_note" rows="2" dense label="الملاحظة"
                    outlined />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit.open = false">
            الغاء
          </v-btn>
          <v-btn color="secondary white--text" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
            @click="submitEdit">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Info dialog -->
    <v-dialog v-model="dialogInfo.open" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض المعلومات</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <table class="info_table">
                  <tr>
                    <th>تاريخ بداية التسجيل</th>
                    <th>تاريخ نهاية التسجيل</th>
                    <th>من قام بالاضافة</th>
                    <th>الملاحظة</th>
                  </tr>
                  <tr>
                    <td>{{ dialogInfo.data.courses_registration_start_date }}</td>
                    <td>{{ dialogInfo.data.courses_registration_end_date }}</td>
                    <td>{{ dialogInfo.data.added_by }}</td>
                    <td>{{ dialogInfo.data.courses_note }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogInfo.open = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Info discount dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="500px">
      <v-card style="border-radius: 0">
        <v-card-title class="headline justify-center">
          هل انت متأكد من حذف هذا الحساب ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
            الغاء
          </v-btn>
          <v-btn color="primary white--text" :loading="dialogDelete.loading" @click="deleteItemConfirm">
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit table dialog -->
    <!--- respondMessageVue -->
    <respondMessageVue :dialogData="dialogData"></respondMessageVue>
  </v-container>
</template>

<script>
// ES modules
import coursesApi from '@/api/courses'
import new_coursesApi from '@/api/new_courses'
import teachersApi from '@/api/teachers'
import respondMessageVue from '@/components/respondMessage.vue'
import numberWithComma from '@/constants/number'
import { encrypt, decrypt } from '@/constants/cryptUrl'

export default {
  name: 'new_courses',

  components: {
    respondMessageVue,
  },

  data: () => ({
    content_url: null,

    start_date: false,

    start_date_edit: false,

    expanded: [],

    time_picker: false,

    time_picker_edit: false,

    start_date_registration: false,

    start_date_registration_edit: false,

    end_date_registration: false,

    end_date_registration_edit: false,

    rate: 0,

    week_days: ['السبت', 'الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة'],

    errors: {},

    is_active_select_items: [
      { text: 'مفعل', value: true },
      { text: 'غير مفعل', value: false }
    ],

    addData: {
      courses_name_id: null,
      courses_duration: null,
      courses_hours: null,
      courses_start_date: null,
      courses_registration_start_date: null,
      courses_registration_end_date: null,
      courses_time: null,
      courses_lecturer: null,
      courses_price: null,
      courses_lecturer_price: null,
      courses_days: [],
      courses_agency_price: null,
      courses_note: null,
    },

    Rules: {
      required: [value => !!value || 'الحقل مطلوب']
    },

    searching: null,

    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortBy: [],
      sortDesc: [],
      search: null,
      firstTime: true
    },

    table: {
      search: null,
      totalData: 0,
      data: [],
      loading: true,

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: '_id'
        },
        {
          text: 'الاسم',
          sortable: false,
          value: 'courses_name'
        },
        {
          text: 'المدة',
          sortable: false,
          value: 'courses_duration'
        },
        {
          text: 'الساعات',
          sortable: false,
          value: 'courses_hours'
        },
        {
          text: 'تاريخ البداية',
          sortable: false,
          value: 'courses_start_date'
        },
        // {
        //   text: 'تاريخ بداية التسجيل',
        //   sortable: false,
        //   value: 'courses_registration_start_date'
        // },
        // {
        //   text: 'تاريخ نهاية التسجيل',
        //   sortable: false,
        //   value: 'courses_registration_end_date'
        // },
        {
          text: 'الوقت',
          sortable: false,
          value: 'courses_time'
        },
        {
          text: 'المحاضر',
          sortable: false,
          value: 'teacher_name'
        },
        {
          text: 'السعر',
          sortable: false,
          value: 'courses_price'
        },
        {
          text: 'مبلغ المحاضر',
          sortable: false,
          value: 'courses_lecturer_price'
        },
        {
          text: 'ايام الدورة',
          width: 1,
          sortable: false,
          value: 'courses_days'
        },
        {
          text: 'مبلغ الوكالة',
          sortable: false,
          value: 'courses_agency_price'
        },
        { text: 'العمليات', value: 'actions', sortable: false }
      ]
    },

    table_items_show: {
      courses_name_id: true,
      courses_duration: true,
      courses_hours: true,
      courses_start_date: false,
      courses_registration_start_date: false,
      courses_registration_end_date: false,
      courses_time: true,
      courses_lecturer: true,
      courses_price: true,
      courses_lecturer_price: true,
      courses_days: true,
      courses_agency_price: true,
      courses_note: false,
    },

    dialogData: {
      open: false,
      color: 'info',
      bodyText: 'test'
    },

    all_courses: {
      data: [],
      loading: false
    },

    all_teachers: {
      data: [],
      loading: false
    },

    editedItem: {
      _id: null,
      courses_name_id: null,
      courses_duration: null,
      courses_hours: null,
      courses_start_date: null,
      courses_registration_start_date: null,
      courses_registration_end_date: null,
      courses_time: null,
      courses_lecturer: null,
      courses_price: null,
      courses_lecturer_price: null,
      courses_days: [],
      courses_agency_price: null,
      courses_note: null,
    },

    dialogDelete: {
      open: false,
      loading: false
    },

    dialogEdit: {
      open: false,
      loading: false,
      isFormValid: false
    },

    dialogInfo: {
      open: false,
      data: {}
    },

    dialogAdd: {
      open: false,
      loading: false,
      isFormValid: false
    },


  }),

  watch: {
    tableOptions: {
      // immediate: false,

      handler() {
        if (!this.tableOptions.firstTime) {
          this.$router.push(
            {
              query: {
                filter: encrypt({
                  page: this.tableOptions.page,
                  limit: this.tableOptions.itemsPerPage,
                  search: this.table.search
                })
              }
            },
            () => { }
          )
        }

        this.tableOptions.firstTime = false

        // this.getDataAxios();
      }
      // deep: true,
    },

    '$route.query.filter': {
      handler() {
        if (!this.$route.query.filter) {
          this.tableOptions.page = 1
          this.tableOptions.itemsPerPage = 10
          this.table.search = null
          this.searching = null
          return
        }
        const filterData = decrypt(this.$route.query.filter)

        let page = filterData.page
        let limit = filterData.limit
        let search = filterData.search

        this.tableOptions.page = +page
        this.tableOptions.itemsPerPage = +limit
        this.table.search = search
        this.searching = search

        this.getDataAxios()
      }
    },

    'table.search': {
      handler() {
        this.tableOptions.page = 1
        this.tableOptions.itemsPerPage = 10
        this.$router.push(
          {
            query: {
              filter: encrypt({
                page: this.tableOptions.page,
                limit: this.tableOptions.itemsPerPage,
                search: this.table.search
              })
            }
          },
          () => { }
        )
      }
    }
  },

  created() {
    this.getDataAxios()
    this.getAllCourses()
    this.getAllTeachers()
  },
  methods: {
    async getDataAxios() {
      this.table.loading = true
      let search = this.table.search
      let page = this.tableOptions.page
      let limit = this.tableOptions.itemsPerPage

      if (!search) {
        search = ''
      }

      const response = await new_coursesApi.get({
        page,
        limit,
        search,
        is_deleted: false,
        is_disable: false
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogFunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    async getAllCourses() {
      this.all_courses.loading = true

      const response = await coursesApi.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.all_courses.loading = false
        this.showDialogFunction(response.data.results, '#FF5252')
      } else {
        this.all_courses.loading = false
        this.all_courses.data = response.data.results
      }
    },

    async getAllTeachers() {
      this.all_teachers.loading = true

      const response = await teachersApi.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.all_teachers.loading = false
        this.showDialogFunction(response.data.results, '#FF5252')
      } else {
        this.all_teachers.loading = false
        this.all_teachers.data = response.data.results
      }
    },

    Edit(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async submitEdit() {
      this.dialogEdit.loading = true

      const response = await new_coursesApi.edit({
        id: this.editedItem._id,
        courses_name_id: this.editedItem.courses_name_id,
        courses_duration: this.editedItem.courses_duration,
        courses_hours: this.editedItem.courses_hours,
        courses_start_date: this.editedItem.courses_start_date,
        courses_registration_start_date: this.editedItem.courses_registration_start_date,
        courses_registration_end_date: this.editedItem.courses_registration_end_date,
        courses_time: this.editedItem.courses_time,
        courses_lecturer: this.editedItem.courses_lecturer,
        courses_price: this.editedItem.courses_price,
        courses_lecturer_price: this.editedItem.courses_lecturer_price,
        courses_days: this.editedItem.courses_days,
        courses_agency_price: this.editedItem.courses_agency_price,
        courses_note: this.editedItem.courses_note,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogFunction(response.data.message, '#FF5252')
      } else {
        this.dialogEdit.loading = false
        this.dialogEdit.open = false
        this.getDataAxios()
        this.showDialogFunction(response.data.message, 'info')
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete.open = true
    },

    async deleteItemConfirm() {
      this.dialogDelete.loading = true

      const response = await new_coursesApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.showDialogFunction(response.data.message, '#FF5252')
      } else {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.getDataAxios()
        this.showDialogFunction(response.data.message, 'info')
      }
    },

    async submitAdd() {

      this.dialogAdd.loading = true

      const response = await new_coursesApi.add({
        courses_name_id: this.addData.courses_name_id,
        courses_duration: this.addData.courses_duration,
        courses_hours: this.addData.courses_hours,
        courses_start_date: this.addData.courses_start_date,
        courses_registration_start_date: this.addData.courses_registration_start_date,
        courses_registration_end_date: this.addData.courses_registration_end_date,
        courses_time: this.addData.courses_time,
        courses_lecturer: this.addData.courses_lecturer,
        courses_price: this.addData.courses_price,
        courses_lecturer_price: this.addData.courses_lecturer_price,
        courses_days: this.addData.courses_days,
        courses_agency_price: this.addData.courses_agency_price,
        courses_note: this.addData.courses_note,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogAdd.open = false
        this.dialogAdd.loading = false
        this.showDialogFunction(response.data.message, '#FF5252')
      } else {
        this.dialogAdd.loading = false
        this.dialogAdd.open = false

        this.addData.courses_name = null
        this.addData.courses_note = null

        this.showDialogFunction(response.data.message, 'info')
        this.getDataAxios()
      }
    },

    search() {
      this.table.search = this.searching
    },

    showDialogFunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },


    removeItemInTableHeader(item) {
      for (const iterator of this.table.headers) {
        iterator.value = item
      }
    },

    isNumberWithComma(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,

    showInfo(item) {
      this.dialogInfo.data = { ...item }
      this.dialogInfo.open = true
    },
  }
}
</script>

