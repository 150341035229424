import axios from "axios";
import { sha512 } from "js-sha512";

class teachersApi {
    async get({ page, limit, search }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/teachers?page=${page}&limit=${limit}&search=${search}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async getAll() {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/teachers/all`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async add({ name, birthday, mobile, address, gender, password_show, note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .post("/teachers", {
                name,
                birthday,
                mobile,
                address,
                note,
                gender,
                password: sha512(password_show),
                password_show,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async edit({ id, name, birthday, mobile, address, gender, password_show, note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .put("/teachers", {
                id,
                name,
                birthday,
                mobile,
                address,
                gender,
                password: sha512(password_show),
                password_show,
                note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async remove(id) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .delete(`/teachers/id/${id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }
}

export default new teachersApi();